var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',[_c('el-col',{attrs:{"span":13}},[_c('span',{staticClass:"searchText"},[_vm._v("运营类型")]),_c('el-select',{staticStyle:{"margin-right":"50px"},attrs:{"placeholder":"Select"},model:{value:(_vm.search.type),callback:function ($$v) {_vm.$set(_vm.search, "type", $$v)},expression:"search.type"}},[_c('el-option',{attrs:{"label":"全部","value":""}}),_c('el-option',{attrs:{"label":"加盟","value":"1"}}),_c('el-option',{attrs:{"label":"直营","value":"0"}})],1),_c('span',{staticClass:"searchText"},[_vm._v("搜索地区")]),_c('el-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入地区名称"},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}})],1),_c('el-col',{attrs:{"span":8}},[_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.resetSearch()}}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList()}}},[_vm._v(" 搜索")]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){_vm.dialog.show = true}}},[_vm._v("添加")])],1)],1),_c('el-table',{staticStyle:{"margin-top":"30px"},attrs:{"data":_vm.table.list}},[_vm._l((Object.keys(_vm.table.label)),function(col){return _c('el-table-column',{key:col,attrs:{"align":"center","label":_vm.table.label[col],"prop":col},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(col === 'disableStatus')?_c('el-switch',{attrs:{"active-value":false,"inactive-value":true},on:{"change":function($event){return _vm.handleSwitchChange(row)}},model:{value:(row.disableStatus),callback:function ($$v) {_vm.$set(row, "disableStatus", $$v)},expression:"row.disableStatus"}}):(col === 'manageType')?_c('div',[_vm._v(_vm._s(row[col] === 1 ? '加盟' : '直营'))]):(col === 'hotSortNum')?_c('div',[_c('el-input',{staticStyle:{"width":"100px"},attrs:{"type":"number"},on:{"blur":function($event){return _vm.handleChangeSort(row)}},model:{value:(row[col]),callback:function ($$v) {_vm.$set(row, col, $$v)},expression:"row[col]"}})],1):_c('div',[_vm._v(_vm._s(row[col]))])]}}],null,true)})}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"small","c":""},on:{"click":function($event){_vm.delDialog = { show: true, id: row.id }}}},[_vm._v("删除")])]}}])})],2),_c('div',{staticClass:"footer"},[_c('el-pagination',{staticClass:"pagination",attrs:{"current-page":_vm.currentPage,"page-sizes":_vm.pageSizes,"page-size":_vm.pageSize,"total":_vm.total,"layout":"total, sizes, prev, pager, next, jumper"},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}})],1),_c('el-dialog',{attrs:{"center":"","visible":_vm.delDialog.show},on:{"close":function($event){_vm.delDialog.show = false}}},[_c('span',[_vm._v("删除后将不在热门地区显示")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.delDialog.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleDel}},[_vm._v("确 定")])],1)]),_c('el-dialog',{attrs:{"title":"添加热门地区","visible":_vm.dialog.show},on:{"close":function($event){_vm.dialog.show = false}}},[_c('el-transfer',{attrs:{"data":_vm.dialog.list,"props":{
        key: 'id',
        label: 'areaName'
      },"titles":['可选地区', '已选地区'],"button-texts":['移除', '添加'],"filterable":""},model:{value:(_vm.dialog.value),callback:function ($$v) {_vm.$set(_vm.dialog, "value", $$v)},expression:"dialog.value"}}),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialog.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.dialog.acting},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }