<!--
 * @Author: Link
 * @Date: 2021-02-03 10:02:24
 * @LastEditTime: 2021-02-03 14:30:53
-->
<template>
  <div>
    <el-row>
      <el-col :span="13">
        <span class="searchText">运营类型</span>
        <el-select v-model="search.type" placeholder="Select" style="margin-right:50px">
          <el-option label="全部" value=""> </el-option>
          <el-option label="加盟" value="1"> </el-option>
          <el-option label="直营" value="0"> </el-option>
        </el-select>
        <span class="searchText">搜索地区</span>
        <el-input v-model="search.name" style="width:200px" placeholder="请输入地区名称"></el-input>
      </el-col>
      <el-col :span="8">
        <el-button type="info" @click="resetSearch()">重置</el-button>
        <el-button type="primary" @click="getList()"> 搜索</el-button>
        <el-button type="success" @click="dialog.show = true">添加</el-button>
      </el-col>
    </el-row>
    <el-table :data="table.list" style="margin-top:30px">
      <el-table-column
        align="center"
        v-for="col in Object.keys(table.label)"
        :key="col"
        :label="table.label[col]"
        :prop="col"
      >
        <template slot-scope="{ row }">
          <el-switch
            v-if="col === 'disableStatus'"
            v-model="row.disableStatus"
            :active-value="false"
            :inactive-value="true"
            @change="handleSwitchChange(row)"
          ></el-switch>
          <div v-else-if="col === 'manageType'">{{ row[col] === 1 ? '加盟' : '直营' }}</div>
          <div v-else-if="col === 'hotSortNum'">
            <el-input type="number" v-model="row[col]" style="width:100px" @blur="handleChangeSort(row)"></el-input>
          </div>
          <div v-else>{{ row[col] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-button @click="delDialog = { show: true, id: row.id }" type="text" size="small" c>删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        class="pagination"
      >
      </el-pagination>
    </div>
    <el-dialog center :visible="delDialog.show" @close="delDialog.show = false">
      <span>删除后将不在热门地区显示</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialog.show = false">取 消</el-button>
        <el-button type="primary" @click="handleDel">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加热门地区" :visible="dialog.show" @close="dialog.show = false">
      <el-transfer
        v-model="dialog.value"
        :data="dialog.list"
        :props="{
          key: 'id',
          label: 'areaName'
        }"
        :titles="['可选地区', '已选地区']"
        :button-texts="['移除', '添加']"
        filterable
      ></el-transfer>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog.show = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="dialog.acting">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      loading: false,
      search: {
        name: '',
        type: ''
      },
      table: {
        list: [],
        label: {
          hotSortNum: '排序',
          id: '地区ID',
          provinceName: '省份',
          areaName: '地区',
          manageType: '运营类型'
        }
      },
      delDialog: {
        id: '',
        show: false
      },
      dialog: {
        searchName: '',
        list: [],
        show: false,
        value: [],
        acting: false
      }
    }
  },
  mounted() {
    this.getNotHotArea()
  },
  methods: {
    resetSearch() {
      this.search = {
        name: '',
        type: ''
      }
      this.getList()
    },
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize,
          hotStatus: 1,
          manageType: this.search.type,
          areaName: this.search.name
        }
      }
      this.$api.base
        .base('areaList', params)
        .then(res => {
          this.table.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '获取列表错误')
        })
        .finally(() => {
          this.loading = false
        })
    },
    getNotHotArea() {
      let params = {
        params: {
          page: 1,
          size: 100,
          hotStatus: 0,
          areaName: this.dialog.searchName
        }
      }
      this.$api.base
        .base('areaList', params)
        .then(res => {
          this.dialog.list = res.list
        })
        .catch(err => {
          this.$message.error(err.msg || '获取列表错误')
        })
    },
    handleDel() {
      this.$api.base
        .base('delHotArea', { id: this.delDialog.id })
        .then(res => {
          if (res) {
            this.delDialog = { id: '', show: false }
            this.$message({ message: '删除成功', type: 'success' })
            this.getList()
          } else {
            this.$message.error('删除失败')
          }
        })
        .catch(err => {
          this.$message.error('删除失败')
        })
    },
    submit() {
      this.$api.base
        .base('addHotArea', { ids: this.dialog.value })
        .then(res => {
          if (res) {
            this.$message({ message: '添加成功', type: 'success' })
            this.dialog.show = false
            this.getList()
            this.getNotHotArea()
          } else {
            this.$message.error('添加失败')
          }
        })
        .catch(err => {
          this.$message.error('添加失败')
        })
    },
    handleChangeSort(row) {
      this.$api.base
        .base('changeHotSort', { id: row.id, sort: row.hotSortNum })
        .then(res => {
          if (res) {
            this.$message({ message: '修改排序成功', type: 'success' })
          } else {
            this.$message.error('修改排序失败')
          }
        })
        .catch(() => {
          this.$message.error('修改排序失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.searchText {
  margin-right: 10px;
}
.media-platform-setting {
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
