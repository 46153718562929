<!--
 * @Author: Link
 * @Date: 2021-01-09 16:14:01
 * @LastEditTime: 2021-03-03 13:58:27
-->
<template>
  <div>
    <el-card class="editContainer">
      <el-tabs>
        <el-tab-pane label="地区列表">
          <div class="searchContainer">
            <el-row :gutter="20" class="query">
              <el-col :span="13">
                <span class="searchText">运营类型</span>
                <el-select v-model="searchInput" placeholder="Select">
                  <el-option label="全部" value=""> </el-option>
                  <el-option label="加盟" value="1"> </el-option>
                  <el-option label="直营" value="0"> </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-button type="info" @click="resetSearch()">重置</el-button>
                <el-button type="primary" @click="getList()"> 搜索</el-button>
                <el-button type="success" @click="handleAdd">添加</el-button></el-col
              >
            </el-row>
          </div>
          <div class="tableContainer">
            <div class="header">
              <!-- <div class="title">地区列表</div> -->
            </div>
            <el-table :data="table.list" style="margin-top:30px">
              <el-table-column
                align="center"
                v-for="col in Object.keys(table.label)"
                :key="col"
                :label="table.label[col]"
                :prop="col"
              >
                <template slot-scope="{ row }">
                  <el-switch
                    v-if="col === 'disableStatus'"
                    v-model="row.disableStatus"
                    :active-value="false"
                    :inactive-value="true"
                    @change="handleSwitchChange(row)"
                  ></el-switch>
                  <div v-else-if="col === 'manageType'">{{ row[col] === 1 ? '加盟' : '直营' }}</div>
                  <div v-else>{{ row[col] }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="{ row }">
                  <!-- <div class="action"> -->
                  <el-button @click="action('edit', row)" type="text" size="small" c>编辑</el-button>
                  <!-- <el-button @click="action('delete', row)" type="text" size="small" class="deleteBtnText">
                删除
              </el-button> -->
                  <!-- </div> -->
                </template>
              </el-table-column>
            </el-table>
            <div class="footer">
              <el-pagination
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
                class="pagination"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="热门地区"><hotArea /></el-tab-pane>
        <el-tab-pane label="多地区商家设置"><manyAreaSetting /></el-tab-pane>
        <el-tab-pane label="商品推荐设置"><productRecommendSetting /></el-tab-pane>
      </el-tabs>
      <!-- 删除组件 -->
      <!-- <bm-deleteItem
        url="/boom-center-admin-service/sysAdmin/area-settings/delete/"
        :timestamp="timestamp"
        @successDelete="successDelete"
      ></bm-deleteItem> -->
    </el-card>

    <el-dialog
      :visible="dialog.show"
      :title="`${dialog.type === 'edit' ? '编辑' : '添加'}地区`"
      @close="dialog.show = false"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="省份" prop="provinceName">
          <el-input v-model.trim="form.provinceName"></el-input>
        </el-form-item>
        <el-form-item label="城市" prop="areaName">
          <el-input v-model.trim="form.areaName"></el-input>
        </el-form-item>

        <el-form-item label="运营类型" prop="radio">
          <el-radio-group v-model="form.manageType" @change="changManageType">
            <el-radio label="0">直营</el-radio>
            <el-radio label="1">加盟</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="二级商户号">
          <el-select v-model="form.platformSubMerchantId" placeholder="选择商户号">
            <el-option-group v-for="group in options" :key="group.label" :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.platformSubMerchantId"
                :label="item.accountName"
                :value="item.platformSubMerchantId"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>

        <template v-if="form.manageType === '1'">
          <el-form-item label="名称" prop="franchiseName">
            <el-input v-model.trim="form.franchiseName"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="mobile">
            <el-input v-model.trim="form.mobile"></el-input>
          </el-form-item>

          <el-form-item label="地址" prop="address">
            <el-input v-model.trim="form.address"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog.show = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="dialog.acting">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import hotArea from './hotArea'
import manyAreaSetting from './manyAreaSetting'
import productRecommendSetting from './productRecommendSetting'

export default {
  mixins: [pagination],
  components: { hotArea, manyAreaSetting, productRecommendSetting },
  data() {
    return {
      options: [
        {
          label: '直营',
          options: []
        },
        {
          label: '加盟',
          options: []
        }
      ],
      searchInput: '',
      // timestamp: 0,
      related: {
        show: false,
        id: ''
      },
      table: {
        label: {
          id: '地区ID',
          provinceName: '省份',
          areaName: '地区',
          manageType: '运营类型',
          disableStatus: '是否启用'
        },
        list: []
      },
      dialog: {
        show: false,
        type: 'add',
        acting: false,
        id: '' //修改的id
      },
      form: {
        areaName: '',
        id: '',
        manageType: '0',
        platformSubMerchantId: '',
        provinceName: '',
        address: '',
        mobile: '',
        franchiseName: ''
      },
      pagination: {
        total: 1,
        size: 10,
        page: 1
      },
      rules: {}
    }
  },
  created() {
    this.rules = this.$initRules([
      // {
      //   label: '省份',
      //   value: 'provinceName',
      //   type: 'input',
      //   required: true
      // },
      {
        label: '城市',
        value: 'areaName',
        type: 'input',
        required: true
      },
      {
        label: '名称',
        value: 'franchiseName',
        type: 'input',
        required: true
      },
      {
        label: '电话',
        value: 'mobile',
        type: 'input',
        required: true
      },
      {
        label: '地址',
        value: 'address',
        type: 'input',
        required: true
      }
    ])

    console.log(this.rules)
    this.getPlatformSubMerchant()
  },
  methods: {
    changManageType() {
      let value = this.manageType === '0' ? false : true
      this.rules.franchiseName.required = value
      this.rules.franchiseName.mobile = value
      this.rules.franchiseName.address = value
    },
    getPlatformSubMerchant() {
      this.$api.base.base('platformSubMerchant').then(res => {
        console.log(res)

        res.forEach(item => {
          console.log(item)

          if (item.operationType === 1) {
            console.log(this.options[1])
            this.options[1].options.push(item)
          } else {
            this.options[0].options.push(item)
          }
        })
      })
    },
    resetSearch() {
      this.searchInput = ''
      this.getList()
    },
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize,
          manageType: this.searchInput
        }
      }
      this.$api.base
        .base('areaSettingList', params)
        .then(res => {
          this.table.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '获取列表错误')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleAdd() {
      this.form = {
        areaName: '',
        id: '',
        manageType: '0',
        platformSubMerchantId: '',
        provinceName: '',
        address: '',
        mobile: '',
        franchiseName: ''
      }
      this.dialog.type = 'add'
      this.dialog.show = true
    },

    handleSwitchChange(row) {
      if (!row.disableStatus) {
        this.$api.base
          .base('enableArea', row.id)
          .then(res => {
            this.$message.success('启用成功')
          })
          .catch(err => {
            this.$message.success('操作失败')
          })
          .finally(() => {
            this.getList(true)
          })
      } else {
        this.$api.base
          .base('disableArea', row.id)
          .then(res => {
            this.$message.success('禁用成功')
          })
          .catch(err => {
            this.$message.success('操作失败')
          })
          .finally(() => {
            this.getList(true)
          })
      }
    },
    action(type, row) {
      if (type === 'edit') {
        this.dialog.id = row.id

        this.$api.base.base('areaSettingDetail', row.id).then(res => {
          if (!res.boundStatus) {
            res.platformSubMerchantId = ''
          }
          this.form = res
          this.form.manageType = this.form.manageType.toString()
          this.dialog.type = 'edit'
          this.dialog.show = true
        })
      }
      // if (type === 'delete') {
      //   this.timestamp = new Date().getTime()
      //   sessionStorage['deleteItemId'] = row.id
      // }
      if (type === 'related') {
        this.related = {
          show: true,
          id: row.id
        }
        this.$emit('related', this.related)
      }
    },
    submit() {
      let params = this.form

      let add = this.dialog.type === 'add'
      let req

      this.$refs['form'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return true
        } else {
          this.dialog.acting = true
          if (add) {
            delete params.id
            req = this.$api.base.base('addAreaSetting', params)
          } else {
            params.id = this.dialog.id
            req = this.$api.base.base('editAreaSetting', params)
          }
          const loading = this.$loading({
            lock: true,
            text: '正在提交，请稍候',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          req
            .then(res => {
              if (res) {
                this.getList(true)
                this.$message({
                  message: `${add ? '添加' : '修改'}成功`,
                  type: 'success'
                })
                this.dialog.show = false
              } else {
                this.$message.error(`${add ? '添加' : '修改'}失败`)
              }
            })
            .catch(err => {
              this.$message.error(err.msg)
            })
            .finally(() => {
              this.dialog.acting = false
              loading.close()
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.editContainer {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
}
.media-platform-setting {
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
