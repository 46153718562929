<template>
  <div>
    <el-table :data="table.list" style="margin-top:30px">
      <el-table-column
        align="center"
        v-for="col in Object.keys(table.label)"
        :key="col"
        :label="table.label[col]"
        :prop="col"
      >
        <template slot-scope="{ row }">
          <div v-if="col === 'recommendProduct'">{{ row[col] === 0 ? '隐藏' : '显示' }}</div>
          <div v-else-if="col === 'hideSoldOutRecommendProduct'">{{ row | filterHide }}</div>

          <div v-else>{{ row[col] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-button @click="edit(row)" type="text" size="small" c>编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible="showDialog"
      title="编辑"
      @close="showDialog = false"
      :close-on-click-modal="false"
      width="350px"
    >
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="是否展示商品推荐" prop="recommendProduct">
          <el-radio-group v-model="form.recommendProduct">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否过滤售罄商品" prop="hideSoldOutRecommendProduct" v-if="form.recommendProduct === 1">
          <el-radio-group v-model="form.hideSoldOutRecommendProduct">
            <el-radio :label="1">过滤</el-radio>
            <el-radio :label="0">不过滤</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      table: {
        label: {
          areaId: '地区id',
          areaName: '地区名称',
          recommendProduct: '是否展示商品推荐',
          hideSoldOutRecommendProduct: '是否过滤售罄商品 '
        },
        list: []
      },
      showDialog: false,
      form: {
        areaId: 0,
        hideSoldOutRecommendProduct: 0,
        recommendProduct: 0
      }
    }
  },
  filters: {
    filterHide(val) {
      console.log(val)

      if (val.recommendProduct === 0) {
        return '---'
      } else if (val.hideSoldOutRecommendProduct === 1) {
        return '过滤'
      } else {
        return '不过滤'
      }
    }
  },

  methods: {
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize
        }
      }
      this.$http
        .get('/boom-center-admin-service/sysAdmin/mallDisplay/list', params)
        .then(res => {
          this.table.list = res
        })
        .catch(err => {
          this.$message.error(err.msg || '获取列表错误')
        })
        .finally(() => {
          this.loading = false
        })
    },
    edit(row) {
      this.form = { ...row }
      this.showDialog = true
    },
    submit() {
      this.$http
        .post('/boom-center-admin-service/sysAdmin/mallDisplay', this.form)
        .then(res => {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.showDialog = false
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.editContainer {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
}
// .footer {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 16px;
// }
</style>
